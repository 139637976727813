// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Link, LazyloadPlaceholder } from '@riseart/common';
import { Section, SectionContent, Wrapper } from '@riseart/layout';
import { HorizontalSlider } from '@riseart/slider';
import { CardArticle } from '@riseart/article';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { Share } from 'shared_components/common/share/Share';
import {
  generateSrcForCDN,
  PicturePlaceholder,
} from 'shared_components/common/artdirection/picture/Picture';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { ShortcutLinks } from 'shared_components/common/links/Shortcut';

const LAZYLOAD_ARTICLE_CONFIG = {
  once: true,
  height: 175,
  offset: 200,
  placeholder: <LazyloadPlaceholder height={175} />,
};

type Props = {
  title?: string | Node,
  subTitle?: string | Node,
  buttons?: Array<{
    external: boolean,
    target?: string,
    text: string,
    href: string,
  }>,
  items: Array<Object>,
};

/**
 * CmsModuleArticlesSlider
 *
 * @param {Props} props
 * @returns {Node}
 */
export function CmsModuleArticlesSlider({ title, subTitle, buttons, items }: Props): Node {
  return (
    <Section background="gray">
      <Wrapper>
        <SectionContent
          title={title}
          subTitle={subTitle}
          headingProps={{ tag: 'h2', level: '2', align: 'center' }}
          headingSubTitleProps={{ tag: 'h3', align: 'center' }}
        >
          <HorizontalSlider columnCount={4}>
            {items && items.length
              ? items.map(
                  ({
                    id,
                    type,
                    slug,
                    title,
                    summary,
                    publishedDate,
                    images,
                    author,
                    categories,
                  }) => {
                    const articleUrl = UrlAssembler.articleDetail(id, slug, type);
                    const authorUrl = author
                      ? UrlAssembler.authorDetail(author.type, author.typeId, author.typeAlias)
                      : null;
                    const imageFile = extractImageFileFromData.articleImage(
                      (images || []).map(({ file, ...rest }) => ({ ...rest, ...file })),
                    );

                    return (
                      <CardArticle
                        key={id}
                        displayStyle={5}
                        title={
                          <Link to={articleUrl} title={title}>
                            {title}
                          </Link>
                        }
                        subtitle={
                          categories && categories[0] ? (
                            <Link
                              to={UrlAssembler.articleCategory(
                                categories[0].uri,
                                categories[0].type,
                              )}
                              title={categories[0].name}
                            >
                              {categories[0].name}
                            </Link>
                          ) : null
                        }
                        description={summary}
                        date={
                          <FormattedDate
                            value={new Date(publishedDate)}
                            year="numeric"
                            month="short"
                            day="2-digit"
                          >
                            {(dateLabel: string) => dateLabel}
                          </FormattedDate>
                        }
                        author={
                          <FormattedMessage
                            id="common.byName"
                            values={{
                              name: authorUrl ? (
                                <Link to={authorUrl} title={author.fullName}>
                                  {author.fullName}
                                </Link>
                              ) : (
                                author.fullName
                              ),
                            }}
                          />
                        }
                        share={
                          <Share
                            theme="dark"
                            contentKey="article"
                            contentValues={{ title }}
                            image={generateSrcForCDN(imageFile)}
                            url={`${LocationManager.get('origin')}${articleUrl}`}
                          />
                        }
                      >
                        <Link to={articleUrl} title={title}>
                          <PicturePlaceholder
                            type="slider.gridLarge"
                            artDirectionKey="article.main"
                            image={imageFile}
                            alt={title}
                            lazyload={LAZYLOAD_ARTICLE_CONFIG}
                          />
                        </Link>
                      </CardArticle>
                    );
                  },
                )
              : null}
          </HorizontalSlider>
          <ShortcutLinks links={buttons} />
        </SectionContent>
      </Wrapper>
    </Section>
  );
}
